import React from 'react';
import { string, func, bool, oneOfType } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useHistory } from 'react-router-dom';
import {
  algoliaIndexNameSelector,
  algoliaQueryIdSelector,
} from '../../containers/SearchPage/SearchPage.duck';
import { useSelector } from 'react-redux';
import { pushEventsAlgolia } from '../../util/api';
import { v4 as uuidv4 } from 'uuid';
import { currentUserIdSelector } from '../../ducks/user.duck';

const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  const isDiscountAvailable = publicData?.discountAvailable === 'yes';

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {isDiscountAvailable ? (
          <>
            <span className={css.strike}>${publicData.sellerPrice}</span>{' '}
            <span className={css.red}>{formattedPrice}</span>
          </>
        ) : (
          formattedPrice
        )}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    fromSearchPage,
    index,
  } = props;
  const history = useHistory();
  const queryID = useSelector(algoliaQueryIdSelector);
  const indexName = useSelector(algoliaIndexNameSelector);
  const currentUserId = useSelector(currentUserIdSelector);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = fromSearchPage ? listing : ensureListing(listing);
  const id = fromSearchPage ? currentListing.productId : currentListing.id.uuid;
  const { title = '', price, publicData } = fromSearchPage
    ? currentListing
    : currentListing?.attributes;
  const slug = createSlug(title);
  const author = fromSearchPage ? ensureUser(listing.author) : ensureUser(currentListing.author);
  const authorName = fromSearchPage
    ? author.profile.displayName
    : author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const handleClick = () => {
    if (fromSearchPage && queryID) {
      let anonymousToken = localStorage.getItem('algolia-anonymous-token');

      if (!anonymousToken) {
        anonymousToken = `anonymous-${uuidv4()}`;
        localStorage.setItem('algolia-anonymous-token', anonymousToken);
      }

      pushEventsAlgolia({
        events: [
          {
            eventType: 'click',
            eventName: 'Product Clicked',
            index: indexName,
            userToken: currentUserId ? currentUserId : anonymousToken,
            ...(currentUserId && { authenticatedUserToken: currentUserId }),
            objectIDs: [id],
            queryID,
            positions: [index + 1],
          },
        ],
      });

    }

    history.push(
      queryID ? `/l/${slug}/${id}?queryID=${queryID}&indexName=${indexName}` : `/l/${slug}/${id}`
    );
  };

  return (
    <div className={classes} onClick={handleClick} style={{ cursor: 'pointer' }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe
          price={new Money(price.amount, price.currency)}
          publicData={publicData}
          config={config}
          intl={intl}
        />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
